<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Ерөнхий мэдээлэл</span>
      </template>

      <account-setting-general
        v-if="accountSettingData"
        :account-setting-data="accountSettingData"
        @update-account-setting="updateAccountSetting"
        :clear-account-setting-data="clearAccountSettingData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Нууц үг солих</span>
      </template>

      <account-setting-password v-if="changePasswordData"
        :change-password-data="changePasswordData"
        @change-password="changePassword"
        :clear-change-password-data="clearChangePasswordData"/>
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import accountSettingStoreModule from '@/views/account-setting/accountSettingStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  
  setup () {

    const ACCOUNT_SETTING_APP_STORE_MODULE_NAME = 'app-account-setting'

    // Register module
    if (!store.hasModule(ACCOUNT_SETTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_SETTING_APP_STORE_MODULE_NAME, accountSettingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_SETTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_SETTING_APP_STORE_MODULE_NAME)
    })

    const blankAccountSettingData = {
      username: '',
      email: '',
      role: null,
      organization_name: '',
      team_names: ''
    }

    const blankChangePasswordData = {
      oldPasswordValue: '',
      newPasswordValue: '',
      reTypePasswordValue: ''
    }

    const accountSettingData = ref(JSON.parse(JSON.stringify(blankAccountSettingData)))
    const clearAccountSettingData = () => {
      accountSettingData.value = JSON.parse(JSON.stringify(blankAccountSettingData))
    }

    const changePasswordData = ref(JSON.parse(JSON.stringify(blankChangePasswordData)))
    const clearChangePasswordData = () => {
      changePasswordData.value = JSON.parse(JSON.stringify(blankChangePasswordData))
    }
    
    const toast = useToast()
    const fetchUserData = () => {
      store.dispatch('app-account-setting/fetchUserProfile', { id: 'amaraa' })
      .then(response => { 
        accountSettingData.value = response.data.response
      })
      .catch(error => {
        if (error.response.status === 404) {
          accountSettingData.value = undefined
        }
      })
    }
    fetchUserData()

    const updateAccountSetting = accountSettingData => {
      store.dispatch('app-account-setting/updateAccountSetting', accountSettingData)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    }

    const changePassword = changePasswordData => {
      store.dispatch('app-account-setting/changePassword', changePasswordData)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    }

    return {
      accountSettingData,
      changePasswordData,
      updateAccountSetting,
      fetchUserData,
      clearAccountSettingData,
      clearChangePasswordData,
      changePassword
    }
  },
  data() {
    return {
      options: {},
    }
  },
  // created() {
  //   store.dispatch('app-todo/fetchUser', { id: 'amaraa' })
  //     .then(response => { this.options.general = response.data.response })
  //     .catch(error => {
  //       if (error.response.status === 404) {
  //         userData.value = undefined
  //       }
  //     })
  // },
}
</script>
