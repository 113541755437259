import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useTaskHandler(props, emit) {
  // ------------------------------------------------
  // userLocal
  // ------------------------------------------------
  const accountSettingLocal = ref(JSON.parse(JSON.stringify(props.accountSettingData.value)))
  const resetAccountSettingLocal = () => {
    accountSettingLocal.value = JSON.parse(JSON.stringify(props.accountSettingData.value))
  }
  watch(props.accountSettingData, () => {
    resetAccountSettingLocal()
  })

  // const changePasswordLocal = ref(JSON.parse(JSON.stringify(props.changePasswordData.value)))
  // const resetChangePasswordLocal = () => {
  //   changePasswordLocal.value = JSON.parse(JSON.stringify(props.changePasswordData.value))
  // }

  // watch(props.changePasswordData, () => {
  //   resetChangePasswordLocal()
  // })
  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const accountSettingData = JSON.parse(JSON.stringify(accountSettingLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.accountSettingData.value.id) emit('update-account-setting', accountSettingData.value)
    else emit('add-task', accountSettingData.value)

    // Close sidebar
    emit('update:is-task-handler-sidebar-active', false)
  }

  // const onChangePassword = () => {
  //   const changePasswordData = JSON.parse(JSON.stringify(changePasswordLocal))

  //   // * If event has id => Edit Event
  //   // Emit event for add/update event
  //   emit('change-password', changePasswordData.value)
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */
  const assigneeOptions = [
    { avatar: require('@/assets/images/avatars/1-small.png'), fullName: 'Jane Foster' },
    { avatar: require('@/assets/images/avatars/3-small.png'), fullName: 'Donna Frank' },
    { avatar: require('@/assets/images/avatars/5-small.png'), fullName: 'Gabrielle Robertson' },
    { avatar: require('@/assets/images/avatars/7-small.png'), fullName: 'Lori Spears' },
    { avatar: require('@/assets/images/avatars/9-small.png'), fullName: 'Sandy Vega' },
    { avatar: require('@/assets/images/avatars/11-small.png'), fullName: 'Cheryl May' },
  ]
  /* eslint-enable global-require */

  const resolveAvatarVariant = tags => {
    if (tags.includes('high')) return 'primary'
    if (tags.includes('medium')) return 'warning'
    if (tags.includes('low')) return 'success'
    if (tags.includes('update')) return 'danger'
    if (tags.includes('team')) return 'info'
    return 'primary'
  }

  const tagOptions = [
    { label: 'Team', value: 'team' },
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
    { label: 'High', value: 'high' },
    { label: 'Update', value: 'update' },
  ]

  return {
    // fetchCategories,
    // fetchProducts,
    // fetchTags,
    // fetchPriorities,
    // fetchTeams,
    // fetchUsers,
    // changePasswordLocal,
    // resetChangePasswordLocal,
    accountSettingLocal,
    resetAccountSettingLocal,

    // UI
    assigneeOptions,
    resolveAvatarVariant,
    tagOptions,
    onSubmit,
    // onChangePassword
  }
}
