<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- form -->
      <b-form class="mt-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
        <b-row>
          <b-col sm="6">
            <validation-provider #default="validationContext" name="Хэрэглэгчийн нэр" rules="required">
              <b-form-group label="Хэрэглэгчийн нэр" label-for="account-username">
                <b-form-input id="account-username" v-model="accountSettingLocal.username" placeholder="Хэрэглэгчийн нэр"
                  name="username" disabled :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col sm="6">
            <validation-provider #default="validationContext" name="И-мэйл" rules="required|email">
              <b-form-group label="И-мэйл" label-for="account-email">
                <b-form-input id="account-email" v-model="accountSettingLocal.email" name="email" placeholder="И-мэйл" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Company -->
          <b-col sm="6" v-if="organizationOptions">
            <validation-provider #default="validationContext" name="Байгууллага" rules="required">
              <b-form-group label="Байгууллага" label-for="account-organization-name">
                <v-select v-model="accountSettingLocal.organization_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="organizationOptions" label="name"
                  :reduce="val => val.name" :clearable="false" input-id="account-organization-name"
                  @input="changeOrganization($event)" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6" v-if="teamOptions">
            <!-- Company -->
            <validation-provider #default="validationContext" name="Баг" rules="required">
              <b-form-group label="Баг" label-for="account-team-name">
                <v-select v-model="accountSettingLocal.team_name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="teamOptions" :reduce="val => val.name" :clearable="false" label="name" input-id="team-code" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- alert -->
          <!-- <b-col cols="12" class="mt-75">
            <b-alert show variant="warning" class="mb-50">
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col> -->
          <!--/ alert -->

          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" type="submit">
              Хадгалах
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2">
              Буцаах
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, toRefs } from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import { required, alphaNum, email } from '@validations'

import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import useAccountSettingHandler from './useAccountSettingHandler'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BImg,
    BFormFile,
    
    BForm,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    accountSettingData: {
      type: Object,
      default: () => { },
    },
    clearAccountSettingData: {
      type: Function,
      required: true,
    },
  },
  created () {
    store.dispatch('app-account-setting/fetchOrganizations', {})
      .then(response => {
        this.organizationOptions = response.data.response
      })
  },
  data () {
    return {
      // optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      organizationOptions: [],
      teamOptions: [],
      required,
      alphaNum,
      email,
      required,
    }
  },
  setup (props, { emit }) {
    const {
      accountSettingLocal,
      resetAccountSettingLocal,
      // UI
      onSubmit,
    } = useAccountSettingHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAccountSettingLocal, props.clearAccountSettingData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      accountSettingLocal,
      resetAccountSettingLocal
    }
  },
  methods: {
    // resetForm() {
    //   this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    // },
    changeOrganization (param) {
      store.dispatch('app-account-setting/fetchTeamsByFilter', { organization_name: param })
        .then(response => {
          this.teamOptions = response.data.response
        })
    },
    // validationForm() {
    //   this.$refs.refFormObserver.validate().then(success => {
    //     if (success) {
    //       // eslint-disable-next-line
    //       alert('form submitted!')
    //     }
    //   })
    // },
  },
}
</script>
<!-- <style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style> -->
