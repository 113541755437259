<template>
  <b-card>
    <!-- form -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form  @submit.prevent="handleSubmit" @reset.prevent="resetForm">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
                label="Хуучин нууц үг"
                label-for="account-old-password"
              >
              <validation-provider #default="{ errors }" name="Хуучин нууц үг" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="oldPasswordValue"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Хуучин нууц үг"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Шинэ нууц үг"
            >
              <validation-provider #default="{ errors }" 
                name="Password"
                vid="Password"
                rules="required|min:8">
                <!-- required|min:8|password -->
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Шинэ нууц үг"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Шинэ нууц үг/давтах/"
            >
            <validation-provider #default="{ errors }" name="Шинэ нууц үг давтах" rules="required|confirmed:Password">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="reTypeNewPasswordValue"
                  :type="passwordFieldTypeReType"
                  name="retype-password"
                  placeholder="Шинэ нууц үг"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconReType"
                    class="cursor-pointer"
                    @click="togglePasswordReType"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Хадгалах
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Буцаах
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useAccountSettingHandler from '@/views/account-setting/useAccountSettingHandler'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      oldPasswordValue: '',
      newPasswordValue: '',
      reTypeNewPasswordValue: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeReType: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconReType() {
      return this.passwordFieldTypeReType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordReType() {
      this.passwordFieldTypeReType = this.passwordFieldTypeReType === 'password' ? 'text' : 'password'
    },
    changePassword () {
      if (this.newPasswordValue == '' || this.oldPasswordValue == '' || this.reTypeNewPasswordValue == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "",
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
    },
    resetForm () {
      alert('reseted')
    },
    handleSubmit () {
      alert('submitted№№№')
    },
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          let param = {
            // username: 'amaraa',
            password: this.oldPasswordValue,
            new_password: this.newPasswordValue,
            retype_new_password: this.reTypeNewPasswordValue
          }
          store.dispatch('app-account-setting/changePasswordAccountSetting', param)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }).catch(error => {
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>
